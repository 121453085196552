var render = function () {
  var _vm$tournament, _vm$tournament$discip, _vm$tournament2, _vm$tournament3, _vm$tournament4, _vm$tournament5, _vm$tournament6, _vm$tournament7, _vm$tournament8, _vm$tournament9, _vm$tournament10, _vm$tournament10$orga, _vm$timezone;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-left"
  }, [_c('div', {
    staticClass: "sidebar"
  }, [_c('div', {
    staticClass: "sidebar-content email-app-sidebar"
  }, [_vm.tournamentLoading ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center h-100"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), !_vm.tournamentLoading && _vm.tournament ? _c('div', {
    staticClass: "email-app-menu"
  }, [_c('div', {
    staticClass: "text-center compose-btn"
  }, [_vm.tournament ? _c('tournament-registration-modal', {
    attrs: {
      "tournament": _vm.tournament
    }
  }) : _vm._e(), _vm.tournament ? _c('tournament-registration-no-team-modal', {
    attrs: {
      "tournament": _vm.tournament
    }
  }) : _vm._e(), _vm.enableJoinButton(_vm.tournament) ? _c('b-button', {
    attrs: {
      "variant": "success",
      "block": ""
    },
    on: {
      "click": _vm.handleJoinTournamentClick
    }
  }, [_vm._v(" Join now ")]) : _vm._e(), _vm.enableShowAlreadyJoinedButton(_vm.tournament) ? _c('b-button', {
    attrs: {
      "block": "",
      "disabled": "",
      "variant": "info"
    }
  }, [_c('span', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v("Already joined")])]) : _vm._e(), _vm.enableCheckinButton(_vm.tournament) ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning",
      "disabled": _vm.alreadyCheckedIn(_vm.tournament)
    },
    on: {
      "click": _vm.handleCheckinClick
    }
  }, [_vm.checkingIn ? _c('b-spinner', {
    attrs: {
      "label": "Checking in...",
      "small": ""
    }
  }) : !_vm.alreadyCheckedIn(_vm.tournament) ? _c('span', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v("Check-in")]) : _vm._e(), _vm.alreadyCheckedIn(_vm.tournament) ? _c('span', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v("Already checked-in")]) : _vm._e()], 1) : _vm._e(), _vm.enableInProgressButton(_vm.tournament) ? _c('b-button', {
    attrs: {
      "block": "",
      "disabled": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v("In progress")])]) : _vm._e(), _vm.enableFinishedButton(_vm.tournament) ? _c('b-button', {
    attrs: {
      "block": "",
      "disabled": "",
      "variant": "dark"
    }
  }, [_c('span', {
    staticClass: "text-white font-weight-bold"
  }, [_vm._v("Finished")])]) : _vm._e()], 1), _c('vue-perfect-scrollbar', {
    staticClass: "sidebar-menu-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('vertical-nav-menu-items', {
    staticClass: "navigation navigation-main",
    attrs: {
      "items": _vm.menuItems
    }
  }), _c('b-row', {
    staticClass: "m-0"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-between align-items-center m-2"
  }, [_c('div', {
    staticClass: "section-label"
  }, [_c('strong', [_vm._v(_vm._s((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : (_vm$tournament$discip = _vm$tournament.discipline) === null || _vm$tournament$discip === void 0 ? void 0 : _vm$tournament$discip.title))])]), _c('b-avatar', {
    attrs: {
      "src": _vm.disciplineImage,
      "size": "35px",
      "square": ""
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', {
    staticClass: "bullet bullet-sm mr-1 bullet-success"
  }), _c('span', [_vm._v(_vm._s(_vm.participantsJoined))])])], 1), ((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.enable_checkin) === true && _vm.tournament.checkin_start_at && _vm.tournament.checkin_close_at ? _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', {
    staticClass: "bullet bullet-sm mr-1 bullet-warning"
  }), _c('span', [_vm._v("Check-in " + _vm._s(_vm.checkinAt))])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', {
    staticClass: "bullet bullet-sm mr-1 bullet-primary"
  }), _c('span', [_vm._v("Start " + _vm._s(_vm.startAt))])])], 1), _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', {
    staticClass: "bullet bullet-sm mr-1 bullet-success"
  }), _c('span', [_vm._v(_vm._s(_vm.tournamentFormat))]), _c('span', [_vm._v(_vm._s(_vm.generalMatchFormat))])])], 1), ((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : _vm$tournament3.status_id) === _vm.TournamentStatusType.PUBLISHED && _vm.timeTillStart !== null ? _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', {
    staticClass: "bullet bullet-sm mr-1 bullet-primary"
  }), _c('span', [_vm._v(_vm._s(_vm.timeTillStart) + " left to join")])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', {
    staticClass: "bullet bullet-sm mr-1 bullet-warning"
  }), _c('span', [_vm._v("Platforms:")]), _vm._l((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : _vm$tournament4.platforms, function (platform, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "ml-1 mr-1",
      attrs: {
        "size": "sm",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(platform.title) + " ")]);
  })], 2)], 1), (_vm$tournament5 = _vm.tournament) !== null && _vm$tournament5 !== void 0 && _vm$tournament5.prizes ? _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('div', [_vm._v("Prizes:")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : _vm$tournament6.prizes)
    }
  })])], 1) : _vm._e(), _c('b-row', {
    staticClass: "m-0 pb-1"
  }, [_c('b-col', [_c('span', [_vm._v("Entry fee: ")]), (_vm$tournament7 = _vm.tournament) !== null && _vm$tournament7 !== void 0 && _vm$tournament7.entry_fee ? _c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(((_vm$tournament8 = _vm.tournament) === null || _vm$tournament8 === void 0 ? void 0 : _vm$tournament8.entry_fee) || 0) + " € ")])], 1) : _vm._e(), !((_vm$tournament9 = _vm.tournament) !== null && _vm$tournament9 !== void 0 && _vm$tournament9.entry_fee) ? _c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" NO ENTRY FREE ")])], 1) : _vm._e()])], 1), _c('b-row', {
    staticClass: "m-0 mt-2"
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-between mr-2"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Event host:")]), _c('span', {
    staticClass: "ml-1"
  }, [_c('feather-icon', {
    staticStyle: {
      "color": "#27AE60"
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }), _vm._v(" " + _vm._s((_vm$tournament10 = _vm.tournament) === null || _vm$tournament10 === void 0 ? void 0 : (_vm$tournament10$orga = _vm$tournament10.organizer) === null || _vm$tournament10$orga === void 0 ? void 0 : _vm$tournament10$orga.name) + " ")], 1)]), _c('div', {
    staticClass: "mb-1 font-small-3 mt-1"
  }, [_vm._v(" Timezone: " + _vm._s((_vm$timezone = _vm.timezone) === null || _vm$timezone === void 0 ? void 0 : _vm$timezone.title) + " ")])])], 1)], 1)], 1) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }