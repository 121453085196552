<template>
  <b-modal
    id="tournament-registration-modal"
    :title="title"
    size="lg"
    @hidden="resetModal"
    @show="calculateTeamsAndPlayers"
  >
    <!-- Join tournament -->
    <b-row>
      <b-col
        v-if="
          tournament?.participant_type_id === 2
        "
      >
        <!-- player -->
        <div>
          <h4 class="mb-4">
            You are about to join tournament. Click Join tournament to reserve
            your spot.
          </h4>
        </div>
      </b-col>

      <b-col
        v-if="
          tournament?.participant_type_id === 1"
      >
        <!-- team -->

        <p v-if="teams.length > 1">
          It seems you have more than one
          {{ tournament?.discipline?.name }} team, please first select your
          team to proceed
        </p>

        <p v-else>
          To join tournament, please select your team from the list below and move players to the lineup.
        </p>

        <!-- Teams -->
        <div class="team-list-group d-flex justify-content-center">
          <b-list-group
            class="flex-nowrap overflow-auto"
            horizontal
          >
            <b-list-group-item
              v-for="(team, index) in teams"
              :key="index"
              :active="selectedTeam.id === team.id"
              tag="div"
              @click="selectTeam(team.id)"
            >
              <div class="d-flex align-items-center flex-column">
                <b-avatar
                  :src="team.avatar_image"
                  class="mr-1"
                  variant="secondary"
                />

                {{ team.name }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <!--/ Teams -->
      </b-col>
    </b-row>

    <!-- Edit lineup -->
    <b-row>
      <b-col>
        <div class="join-tournament p-2">
          <!-- Available players -->
          <div
            class="available-player-list"
          >
            <h5 class="text-primary font-weight-bold">
              Team members:
            </h5>

            <draggable
              :list="availablePlayers"
              class="list-group list-group-flush  cursor-move d-flex flex-row"
              group="players"
              tag="div"
            >
              <b-list-group-item
                v-for="player in availablePlayers"
                :key="player.user.id"
                tag="div"
                class="flex-fill "
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="player.user.avatar_image"
                    variant="primary"
                  />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ player.user.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </div>

          <!-- Lineup -->
          <div
            class="player-list mt-2"
          >
            <h5 class="text-primary font-weight-bold mb-2">
              Lineup:
            </h5>

            <!-- draggable -->
            <draggable
              :list="lineup"
              class="list-group list-group-flush cursor-move"
              group="players"
              tag="div"
            >
              <b-list-group-item
                v-for="listItem in lineup"
                :key="listItem.user.id"
                tag="div"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="listItem.user.avatar_image"
                    variant="primary"
                  />

                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.user.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </div>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-row>
        <dismissible-message-box
          :show="showErrorNotifications"
          :variant="MessageType.ERROR"
          class="p-1"
          @onDismissibleMessageBoxDismissed="showErrorNotifications = false"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ errors[0] }}
        </dismissible-message-box>

        <dismissible-message-box
          :show="showSuccessNotifications"
          :variant="MessageType.SUCCESS"
          class="p-1"
          @onDismissibleMessageBoxDismissed="showSuccessNotifications = false"
        >
          You successfully joined tournament! This window will close in 5 seconds.
        </dismissible-message-box>
      </b-row>

      <!-- Join registration -->
      <b-row v-if="showRegisterButton">
        <b-col>
          <spinning-button
            :is-loading="registering"
            @click.native="handleRegisterClick"
          >
            Register
          </spinning-button>
        </b-col>
      </b-row>

      <!-- Cancel registration -->
      <b-row v-if="showCancelRegistrationButton">
        <b-col>
          <spinning-button
            :is-loading="cancelingRegistration"
            variant="outline-primary"
            @click.native="handleCancelRegistrationClick"
          >
            Cancel registration
          </spinning-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BCardText,
  BCol,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import SpinningButton from '@/components/SpinningButton.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import { MemberRoleType } from '@/constants/MemberRoleType'

export default {
  components: {
    DismissibleMessageBox,
    SpinningButton,
    BModal,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    draggable,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    tournament: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      MessageType,
      TournamentParticipantType,
      MemberRoleType,
      selectedTeam: {},
      availablePlayers: [],
      lineup: [],
      backup: [],
      showErrorNotifications: false,
      showSuccessNotifications: false,
      errors: [],
      registering: false,
      cancelingRegistration: false,
      teams: [],
      tournamentLocal: this.tournament,
    }
  },
  computed: {
    title() {
      return `Join ${this.tournament?.name} tournament`
    },
    showCancelRegistrationButton() {
      return false
    },
    showRegisterButton() {
      return true
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  methods: {

    selectTeam(id) {
      this.selectedTeam = {}
      this.availablePlayers = {}
      this.lineup = []
      this.backup = []

      this.selectedTeam = this.teams.filter(team => team.id === id)
        .pop()

      this.availablePlayers = Array.from(new Set(this.selectedTeam.members?.map(item => item.user_id)))
      // eslint-disable-next-line no-shadow,camelcase
        .map(user_id => this.selectedTeam.members.find(item => item.user_id === user_id))
    },
    async resetModal() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.cancelingRegistration = false
      this.selectedTeam = {}
      this.availablePlayers = []
      this.lineup = []
      this.backup = []

      await this.$store.dispatch('tournament/fetchTournament', this.tournament.id)
    },
    async handleCancelRegistrationClick() {
      this.registering = false

      this.cancelingRegistration = true

      await this.$api.tournament.withdrawFromTournament()

      this.cancelingRegistration = false
    },
    calculateTeamsAndPlayers() {
      if (this.tournament?.participant_type_id === TournamentParticipantType.team) {
        this.teams = this.me?.teams?.filter(team => (
          team.disciplines.some(
            discipline => discipline.discipline.id === this.tournament?.discipline.id,
          )
            && team.members.some(
              member => member.user_id === this.me.id && (member.role.id === MemberRoleType.OWNER || member.role.id === MemberRoleType.TEAM_LEADER),
            )
        ))
      }

      if (this.tournament?.participant_type_id === TournamentParticipantType.player) {
        this.availablePlayers = [this.me]
      }
    },
    async handleRegisterClick() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.cancelingRegistration = false

      if (
        this.lineup.length === 0
          || (this.tournament.participant_type_id === TournamentParticipantType.team
              && this.lineup.length < this.tournament.players_per_team)
      ) {
        this.showErrorNotifications = true

        this.errors.push(
          `You must select at least ${this.tournament.players_per_team} players to join tournament`,
        )

        return
      }

      this.registering = true

      const { success } = await this.$api.tournament.joinTournament({
        tournament_id: this.tournament.id,
        participant_type: this.tournament?.participant_type_id === TournamentParticipantType.PLAYER ? 'player' : 'team',
        participant_id: this.tournament?.participant_type_id === TournamentParticipantType.PLAYER ? this.me.id : this.selectedTeam.id,
        lineup: this.tournament?.participant_type_id === TournamentParticipantType.PLAYER ? [this.me.id] : this.lineup.map(teamMember => teamMember.user.id),
      })

      this.registering = false

      if (success === false) {
        this.errors.push(
          'There was an error while joining tournament. Please try again later or contact our support.',
        )

        this.showErrorNotifications = true

        return
      }

      this.showSuccessNotifications = true

      setTimeout(() => {
        this.$bvModal.hide('tournament-registration-modal')
      }, 5000)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.dark-layout {
  .player-list {
    .list-group {
      flex-wrap: wrap;
      flex-direction: row;

      .list-group-item {
        flex: 1;
        border: none;
        max-width: 40%;

        &:hover,
        &:active {
          background: none !important;
        }
      }

      &:empty {
        color: #d0d2d6;
        font-size: 0.857rem;
        padding: 1rem;
        text-align: center;

        &:before {
          content: 'No players selected. Hold and drop an available player here.';
        }
      }
    }
  }

  .available-player-list {
    .list-group {
      flex-wrap: wrap;
      flex-direction: row;

      .list-group-item {
        flex: 1;
        border: none;
        max-width: 40%;

        &:hover,
        &:active {
          background: none !important;
        }
      }

      &:empty {
        color: #d0d2d6;
        font-size: 0.857rem;
        padding: 1rem;
        text-align: center;

        &:before {
          content: 'Hold and drop a player below if you want to remove it.';
        }
      }
    }
  }
}
</style>
