<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list ml-lg-1">
      <router-view :key="$route.path" />
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <tournament-left-sidebar
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import TournamentLeftSidebar from '@/views/tournament/TournamentLeftSidebar.vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import { useRoute } from 'vue2-helpers/vue-router'
import EventBus from '@/common/event-bus'
import { onMounted, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    TournamentLeftSidebar,

  },
  methods: {},
  setup() {
    const route = useRoute()
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    store.dispatch('tournament/fetchTournament', Number(route.params.id))

    const handleEvent = visible => {
      mqShallShowLeftSidebar.value = visible
    }

    onMounted(() => {
      EventBus.$on('show-tournament-sidebar', handleEvent)
    })

    onUnmounted(() => {
      EventBus.$off('show-tournament-sidebar', handleEvent)
    })

    return {
      perfectScrollbarSettings,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.sidebar-toggle {
  padding: 10px;
  float: left;
  background-color: #293046;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
