var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('div', {
    staticClass: "body-content-overlay",
    class: {
      'show': _vm.mqShallShowLeftSidebar
    },
    on: {
      "click": function click($event) {
        _vm.mqShallShowLeftSidebar = false;
      }
    }
  }), _c('div', {
    staticClass: "email-app-list ml-lg-1"
  }, [_c('router-view', {
    key: _vm.$route.path
  })], 1), _c('portal', {
    attrs: {
      "to": "content-renderer-sidebar-left"
    }
  }, [_c('tournament-left-sidebar', {
    class: {
      'show': _vm.mqShallShowLeftSidebar
    },
    on: {
      "close-left-sidebar": function closeLeftSidebar($event) {
        _vm.mqShallShowLeftSidebar = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }