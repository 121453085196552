var render = function () {
  var _vm$tournament, _vm$tournament$discip, _vm$me;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "tournament-registration-no-team-modal",
      "title": _vm.title,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide('tournament-registration-no-team-modal');
            }
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', [!_vm.myTeamHasRequiredDiscipline ? _c('div', {
    staticClass: "d-flex flex-column align-items-center m-2"
  }, [_c('h5', [_vm._v("It seems none of your teams have tournament required discipline.")]), _c('h5', [_vm._v("Please edit your team and add " + _vm._s((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : (_vm$tournament$discip = _vm$tournament.discipline) === null || _vm$tournament$discip === void 0 ? void 0 : _vm$tournament$discip.title) + " to your teams discipline.")]), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleEditTeam
    }
  }, [_vm._v(" Edit team ")])], 1) : _vm._e(), ((_vm$me = _vm.me) === null || _vm$me === void 0 ? void 0 : _vm$me.teams.length) === 0 ? _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(" It seems your have no teams. Please click \"create team\" to continue. ")]), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.handleCreateTeam
    }
  }, [_vm._v(" Create new team ")])], 1) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }