<template>
  <b-modal
    id="tournament-registration-no-team-modal"
    :title="title"
    size="lg"
  >
    <!-- No team or discipline -->
    <b-row>
      <b-col>
        <div
          v-if="!myTeamHasRequiredDiscipline"
          class="d-flex flex-column align-items-center m-2"
        >
          <h5>It seems none of your teams have tournament required discipline.</h5>
          <h5>Please edit your team and add {{ tournament?.discipline?.title }} to your teams discipline.</h5>
          <b-button
            variant="primary"
            class="mt-2"
            @click="handleEditTeam"
          > Edit team
          </b-button>
        </div>
        <div
          v-if="me?.teams.length === 0"
          class="d-flex flex-column align-items-center"
        >
          <h5 class="text-center">
            It seems your have no teams. Please click "create team" to continue.
          </h5>
          <b-button
            variant="success"
            @click="handleCreateTeam"
          > Create new team
          </b-button>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-row>
        <b-col>
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('tournament-registration-no-team-modal')"
          >
            Close
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import MessageType from '@/constants/MessageType'
import { MemberRoleType } from '@/constants/MemberRoleType'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    tournament: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      MessageType,
      TournamentParticipantType,
      MemberRoleType,
      teams: [],
      tournamentLocal: this.tournament,
    }
  },
  computed: {
    title() {
      return 'Missing team or discipline'
    },
    me() {
      return this.$store.getters['user/getMe']
    },
    myTeamHasRequiredDiscipline() {
      return this.me?.teams.some(team => team.disciplines.some(discipline => discipline.discipline.id === this.tournament?.discipline?.id))
    },
  },
  watch: {
    tournament(newVal) {
      this.tournamentLocal = newVal
    },
  },
  methods: {
    async handleEditTeam() {
      await this.$router.push({
        name: 'account-settings',
        params: {
          tab: 'teams',
        },
      })
    },
    async handleCreateTeam() {
      await this.$router.push({
        name: 'team-create',
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.dark-layout {
  .player-list {
    .list-group {
      flex-wrap: wrap;
      flex-direction: row;

      .list-group-item {
        flex: 1;
        border: none;
        max-width: 40%;

        &:hover,
        &:active {
          background: none !important;
        }
      }

      &:empty {
        color: #d0d2d6;
        font-size: 0.857rem;
        padding: 1rem;
        text-align: center;

        &:before {
          content: 'No players selected. Hold and drop an available player here.';
        }
      }
    }
  }

  .available-player-list {
    .list-group {
      flex-wrap: wrap;
      flex-direction: row;

      .list-group-item {
        flex: 1;
        border: none;
        max-width: 40%;

        &:hover,
        &:active {
          background: none !important;
        }
      }

      &:empty {
        color: #d0d2d6;
        font-size: 0.857rem;
        padding: 1rem;
        text-align: center;

        &:before {
          content: 'Hold and drop a player below if you want to remove it.';
        }
      }
    }
  }
}
</style>
