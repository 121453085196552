var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament3, _vm$tournament3$disci;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "tournament-registration-modal",
      "title": _vm.title,
      "size": "lg"
    },
    on: {
      "hidden": _vm.resetModal,
      "show": _vm.calculateTeamsAndPlayers
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-row', [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.showErrorNotifications,
            "variant": _vm.MessageType.ERROR
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showErrorNotifications = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.showSuccessNotifications,
            "variant": _vm.MessageType.SUCCESS
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showSuccessNotifications = false;
            }
          }
        }, [_vm._v(" You successfully joined tournament! This window will close in 5 seconds. ")])], 1), _vm.showRegisterButton ? _c('b-row', [_c('b-col', [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.registering
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.handleRegisterClick($event);
            }
          }
        }, [_vm._v(" Register ")])], 1)], 1) : _vm._e(), _vm.showCancelRegistrationButton ? _c('b-row', [_c('b-col', [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.cancelingRegistration,
            "variant": "outline-primary"
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.handleCancelRegistrationClick($event);
            }
          }
        }, [_vm._v(" Cancel registration ")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('b-row', [((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.participant_type_id) === 2 ? _c('b-col', [_c('div', [_c('h4', {
    staticClass: "mb-4"
  }, [_vm._v(" You are about to join tournament. Click Join tournament to reserve your spot. ")])])]) : _vm._e(), ((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.participant_type_id) === 1 ? _c('b-col', [_vm.teams.length > 1 ? _c('p', [_vm._v(" It seems you have more than one " + _vm._s((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : (_vm$tournament3$disci = _vm$tournament3.discipline) === null || _vm$tournament3$disci === void 0 ? void 0 : _vm$tournament3$disci.name) + " team, please first select your team to proceed ")]) : _c('p', [_vm._v(" To join tournament, please select your team from the list below and move players to the lineup. ")]), _c('div', {
    staticClass: "team-list-group d-flex justify-content-center"
  }, [_c('b-list-group', {
    staticClass: "flex-nowrap overflow-auto",
    attrs: {
      "horizontal": ""
    }
  }, _vm._l(_vm.teams, function (team, index) {
    return _c('b-list-group-item', {
      key: index,
      attrs: {
        "active": _vm.selectedTeam.id === team.id,
        "tag": "div"
      },
      on: {
        "click": function click($event) {
          return _vm.selectTeam(team.id);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center flex-column"
    }, [_c('b-avatar', {
      staticClass: "mr-1",
      attrs: {
        "src": team.avatar_image,
        "variant": "secondary"
      }
    }), _vm._v(" " + _vm._s(team.name) + " ")], 1)]);
  }), 1)], 1)]) : _vm._e()], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "join-tournament p-2"
  }, [_c('div', {
    staticClass: "available-player-list"
  }, [_c('h5', {
    staticClass: "text-primary font-weight-bold"
  }, [_vm._v(" Team members: ")]), _c('draggable', {
    staticClass: "list-group list-group-flush  cursor-move d-flex flex-row",
    attrs: {
      "list": _vm.availablePlayers,
      "group": "players",
      "tag": "div"
    }
  }, _vm._l(_vm.availablePlayers, function (player) {
    return _c('b-list-group-item', {
      key: player.user.id,
      staticClass: "flex-fill ",
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "src": player.user.avatar_image,
        "variant": "primary"
      }
    }), _c('div', {
      staticClass: "ml-25"
    }, [_c('b-card-text', {
      staticClass: "mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(player.user.name) + " ")])], 1)], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "player-list mt-2"
  }, [_c('h5', {
    staticClass: "text-primary font-weight-bold mb-2"
  }, [_vm._v(" Lineup: ")]), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "list": _vm.lineup,
      "group": "players",
      "tag": "div"
    }
  }, _vm._l(_vm.lineup, function (listItem) {
    return _c('b-list-group-item', {
      key: listItem.user.id,
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "src": listItem.user.avatar_image,
        "variant": "primary"
      }
    }), _c('div', {
      staticClass: "ml-25"
    }, [_c('b-card-text', {
      staticClass: "mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(listItem.user.name) + " ")])], 1)], 1)]);
  }), 1)], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }